import { LogoutOutlined, PhoneOutlined, ToolOutlined } from "@ant-design/icons";
import { Avatar, Popover } from "antd";
import userImg from "assets/vendors/image/user.png";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useAuth } from "../../authentication";


const UserInfo = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { userSignOut } = useAuth();
  const gotoProfile = () => {
    window.open(`http://23.81.64.69:8000/admin`, '_blank', 'noopener,noreferrer');
  };

  const enlis_program = Cookies.get("enlis_program");

  // get the user role by program
  const role_obj = authUser?.programs_roles?.filter((obj) => {
    return obj.programs === enlis_program;
  });

  const roles = authUser.programs_roles?.map((item) => {
    return item?.roles;
  });

  const publicAdmin = authUser?.facility_type === "public" && authUser.programs_roles?.some((role) => role?.roles === "Admin");

  const publicStaff = authUser?.facility_type === "public" && authUser.programs_roles?.some((role) => role?.roles !== "Admin");

  const clinicStaffs = authUser?.facility_type === "Clinical";
  const userMenuOptions = (
    <ul className="gx-user-popover">
       <li onClick={gotoProfile}>
        <ToolOutlined style={{ fontSize: "16px", color: "#6F42C1" }} />
        <span className="gx-user-menu">Admin</span>
      </li>
      <li onClick={userSignOut}>
        <PhoneOutlined style={{ fontSize: "16px", color: "#377E21" }} />
        <span className="gx-user-menu">Help&nbsp;&amp;Support</span>
      </li>
      <li onClick={userSignOut}>
        <LogoutOutlined style={{ fontSize: "16px", color: "#DC3545" }} />
        <span className="gx-user-menu">Logout</span>
      </li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <span className="gx-pointer gx-flex-row gx-align-items-center gx-enlis-user">
        {/* <Avatar src={"https://via.placeholder.com/150"} className="gx-avatar gx-pointer" alt=""/> */}
        <div className="gx-profile-banner-avatar gx-p-0">
          <Avatar className="gx-size-20" src={userImg} />
        </div>
        <div className="gx-media-body gx-task-item-content">
          <div className="gx-task-item-content-left">
            <h4 className="gx-text-no-truncate gx-task-item-title gx-text-capitalize">
              {authUser?.username}
            </h4>

            <p className="gx-text-grey gx-text-no-truncate gx-fs-sm gx-mb-0">
              {publicStaff && (
                <span>{role_obj?.length > 0 ? role_obj[0]?.roles : ""}</span>
              )}
              {/* Public facility and admin */}
              {(publicAdmin || clinicStaffs) && (
                <span>{roles?.length > 0 ? roles[0] : ""}</span>
              )}
            </p>
          </div>
          <i className="icon icon-chevron-down gx-pl-1 gx-enlis-user-icon" />
          {/*<div className="gx-task-item-content-right">*/}
          {/*  {getStatus(status)}*/}
          {/*</div>*/}
        </div>
      </span>
    </Popover>
  );
};

export default UserInfo;
