import {
  DASHBOARD_DATA,
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  LINE_DATA,
  MAP_DATA,
  RAW_DATA,
  RECENT_DATA,
  SET_FILTER_TYPE,
  SHOW_MESSAGE,
  TERMS_DATA,
  UPDATE_LGA,
  UPDATE_LINE_MAP,
  UPDATE_PERIOD,
  UPDATE_PROGRAM,
  UPDATE_STATE,
  UPDATED_RAW_DATA,
} from "constants/ActionTypes";
import { httpClient } from "util/Api";

//For setting Filter type
export const setFilterType = (filterType) => {
  return {
    type: SET_FILTER_TYPE,
    payload: filterType,
  };
};

export const setCurrentPrograms = (program) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROGRAM,
      payload: program,
    });
  };
};

export const setLineMap = (line_map) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LINE_MAP,
      payload: line_map,
    });
  };
};

export const setCurrentPeriod = (date_period) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PERIOD,
      payload: date_period,
    });
  };
};

export const setCurrentState = (states) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_STATE,
      payload: states,
    });
  };
};

export const setCurrentLga = (lga) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LGA,
      payload: lga,
    });
  };
};

export const fetchSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || "",
    });
  };
};
export const fetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const fetchMapData = (start_date, end_date, term = null) => {
  let _params = `/map_data/?start_date=${start_date}&end_date=${end_date}`;
  if (term) {
    _params += `&term=${term.toLowerCase()}`;
  }
  return async (dispatch) => {
    dispatch({ type: "mapIsLoading", payload: true });
    dispatch(fetchStart());
    dispatch({ type: MAP_DATA, payload: [] });
    await httpClient
      .get(_params)
      .then((res) => {
        dispatch(fetchSuccess());
        dispatch({ type: MAP_DATA, payload: res.data?.mapData || [] });
        dispatch({ type: "mapIsLoading", payload: false });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        dispatch({ type: "mapIsLoading", payload: false });
        throw error;
      });
  };
};

/**
 * @function fetchLineData
 * @description get all trend data
 */
export const fetchLineData = (start_date, end_date, term = null) => {
  let _params = `/line_data/?start_date=${start_date}&end_date=${end_date}`;
  if (term) {
    _params += `&term=${term.toLowerCase()}`;
  }
  return async (dispatch) => {
    dispatch(fetchStart());
    dispatch({ type: LINE_DATA, payload: null });
    await httpClient
      .get(_params)
      .then((res) => {
        dispatch(fetchSuccess());
        dispatch({ type: LINE_DATA, payload: res.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

/**
 * @function recentData
 * @description get all recent data
 */
export const recentData = (start_date, end_date, term = null) => {
  let _params = `/recent_data/?start_date=${start_date}&end_date=${end_date}`;
  if (term) {
    _params += `&term=${term.toLowerCase()}`;
  }
  return async (dispatch) => {
    dispatch(fetchStart());
    dispatch({ type: RECENT_DATA, payload: null });
    await httpClient
      .get(_params)
      .then((res) => {
        dispatch(fetchSuccess());
        dispatch({ type: RECENT_DATA, payload: res.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

/**
 * @function getRawData
 * @description get all raw data
 */
export const getRawData = (start_date, end_date, term = null) => {
  let _params = `/raw_data/?start_date=${start_date}&end_date=${end_date}`;
  if (term) {
    _params += `&term=${term.toLowerCase()}`;
  }
  return async (dispatch) => {
    dispatch(fetchStart());
    dispatch({ type: RAW_DATA, payload: null });
    await httpClient
      .get(_params)
      .then((res) => {
        dispatch(fetchSuccess());
        // sort the raw data by date, latest first

        const _data =
          res?.data?.rawData?.sort((a, b) => {
            return new Date(b.postdate) - new Date(a.postdate);
          }) ?? [];

        dispatch({ type: RAW_DATA, payload: _data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const getTerms = () => {
  const _params = `/terms/`;
  return async (dispatch) => {
    // dispatch(fetchStart());
    await httpClient
      .get(_params)
      .then((res) => {
        dispatch(fetchSuccess());
        // make all the text capitalized the first letter
        res?.data?.forEach((item) => {
          item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
        });
        dispatch({ type: TERMS_DATA, payload: res.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const updateRawData = ({ id, formData }) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .patch(`/raw_data/${id}/`, formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATED_RAW_DATA,
            payload: res?.data,
          });
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      })
      .finally((error) => {
        dispatch(fetchEnd());
      });
  };
};

export const getDashboardData = (
  start_week,
  start_year,
  end_week,
  end_year,
  district
) => {
  let _params = `/dashboard_data/?start_week=${start_week}&end_week=${end_week}&district=${district}&start_year=${start_year}&end_year=${end_year}`;
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(_params)
      .then((res) => {
        dispatch(fetchSuccess());
        dispatch({ type: DASHBOARD_DATA, payload: res.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};
