import { Layout } from 'antd';
import UserInfo from "components/UserInfo";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleCollapsedSideNav } from "../../../appRedux/actions";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
import HorizontalNav from "../HorizontalNav";

const {Header} = Layout;


const InsideHeader = () => {
  const navCollapsed = useSelector(({common}) => common.navCollapsed);
  const width = useSelector(({common}) => common.width);
  const dispatch = useDispatch();




  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub-popover">
  //       {languageData.map(language =>
  //         <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
  //           dispatch(switchLanguage(language))
  //         }>
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       )}
  //     </ul>
  //   </CustomScrollbars>);



  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      {/*<div className="gx-header-horizontal-top">*/}
      {/*  <div className="gx-container">*/}
      {/*    <div className="gx-header-horizontal-top-flex">*/}
      {/*      <marquee behavior="scroll" direction="left">*/}
      {/*      <div className="gx-header-horizontal-top-left">*/}
      {/*        <i className="icon icon-alert gx-mr-3"/>*/}
      {/*        <p className="gx-mb-0 gx-text-truncate"><IntlMessages id="app.announced"/></p>*/}
      {/*      </div>*/}
      {/*      </marquee>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Header
        className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   dispatch(toggleCollapsedSideNav(!navCollapsed));
                 }}
              />
            </div>
            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
              <img alt="" src="/assets/images/isurveillance2.png"/> </Link>
            <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              <img alt="" src="/assets/images/isurveillance2.png"/> </Link>


            {width >= TAB_SIZE && (
              <div className="gx-header-horizontal-nav" style={{width: "65%"}}>
                <HorizontalNav/>
              </div>
            )}
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-user-nav"><UserInfo/></li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
};

export default InsideHeader;
