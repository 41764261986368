import { Menu } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      className="gx-d-flex gx-justify-content-around gx-gap-2"
    >
      <SubMenu
        style={{ minWidth: "9rem" }}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="event"
        title={<IntlMessages id="sidebar.event" />}
      >
        <Menu.Item key="dashboards/lineGraph">
          <Link to="/dashboards/lineGraph">
            <i className="icon icon-chart-line " />
            <IntlMessages id="sidebar.lineGraph" />
          </Link>
        </Menu.Item>

        <Menu.Item key="dashboards/mapView">
          <Link to="/dashboards/mapView">
            <i className="icon icon-map-directions " />
            <IntlMessages id="sidebar.mapView" />
          </Link>
        </Menu.Item>

        <Menu.Item key="dashboards/events">
          <Link to="/dashboards/events">
            <i className="icon icon-table " />
            <IntlMessages id="sidebar.events" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        style={{ minWidth: "9rem" }}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="idsr"
        title={<IntlMessages id="sidebar.idsr" />}
      >
        <Menu.Item key="dashboards/idsrAggregate">
          <Link to="/dashboards/idsrAggregate">
            <i className="icon icon-family " />
            <IntlMessages id="sidebar.idsrAggregate" />
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboards/idsrCaseBased">
          <Link to="/dashboards/idsrCaseBased">
            <i className="icon icon-map-drawing " />
            <IntlMessages id="sidebar.idsrCaseBased" />
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboards/syndromic">
          <Link to="/dashboards/syndromic">
            <i className="icon icon-visits " />
            <IntlMessages id="sidebar.syndromic" />
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboards/laboratory">
          <Link to="/dashboards/laboratory">
            <i className="icon icon-filter " />
            <IntlMessages id="sidebar.laboratory" />
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        style={{ minWidth: "9rem" }}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="animalHealth"
        title={<IntlMessages id="sidebar.animalHealth" />}
      >
        <Menu.Item key="dashboards/zoonoticDiseases">
          <Link to="/dashboards/zoonoticDiseases">
            <i className="icon icon-data-display " />
            <IntlMessages id="sidebar.zoonoticDiseases" />
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboards/predictOutbreaks">
          <Link to="/dashboards/predictOutbreaks">
            <i className="icon icon-map-drawing " />
            <IntlMessages id="sidebar.predictOutbreaks" />
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        style={{ minWidth: "12rem" }}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="environmentHealth"
        title={<IntlMessages id="sidebar.environmentHealth" />}
      >
        <Menu.Item key="dashboards/climaticPatterns">
          <Link to="/dashboards/climaticPatterns">
            <i className="icon icon-table-data " />
            <IntlMessages id="sidebar.climaticPatterns" />
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboards/waterContamination">
          <Link to="/dashboards/waterContamination">
            <i className="icon icon-error " />
            <IntlMessages id="sidebar.waterContamination" />
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboards/chemicalRadiationNuclearMonitoring">
          <Link to="/dashboards/chemicalRadiationNuclearMonitoring">
            <i className="icon icon-company " />
            <IntlMessages id="sidebar.chemicalRadiationNuclearMonitoring" />
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        style={{ minWidth: "6rem" }}
        popupClassName={getNavStyleSubMenuClass(navStyle)}
        key="antiMicrobial"
        title={<IntlMessages id="sidebar.antiMicrobial" />}
      >
        <Menu.Item key="dashboards/antiMicrobial">
          <Link to="/dashboards/antiMicrobial">
            <i className="icon icon-progress " />
            <IntlMessages id="sidebar.antiMicrobial" />
          </Link>
        </Menu.Item>
        <Menu.Item key="dashboards/foodSafety">
          <Link to="/dashboards/foodSafety">
            <i className="icon icon-noodles " />
            <IntlMessages id="sidebar.foodSafety" />
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;
