/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchError,
  fetchStart,
  fetchEnd,
  fetchSuccess,
} from "appRedux/actions";
import { setAuthUser, userAuthSignOut } from "appRedux/actions/Auth";
import { httpClient } from "util/Api";
import { setInitUrl } from "appRedux/actions/Auth";
import Cookies from "js-cookie";

export const useProvideAuth = () => {
  const [isLoadingUser, setLoadingUser] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = (user, callbackFun) => {
    dispatch(fetchStart());
    httpClient
      .post("/login/", user)
      .then(({ data }) => {
        if (data.token) {
          Cookies.set("enlis_token", JSON.stringify(data.token));
          // httpClient.defaults.headers.Authorization = 'Bearer ' + data.token;
          dispatch(fetchSuccess("Successfully logged in"));
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError(data.error));
        }
      })
      .catch((error) => {
        const message = error.response?.data?.detail;
        clearUser();
        dispatch(fetchError(message));
        throw new Error(message);
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  /**
   * @function getAuthUser
   * @description get authenticated user's data
   */
  const getAuthUser = () => {
    dispatch(fetchStart());
    httpClient
      .get("/users/me/")
      .then(({ data }) => {
        if (data) {
          dispatch(fetchSuccess());
          setUser(data);
          return;
        }
        dispatch(fetchError(data.error));
      })
      .catch((error) => {
        clearUser();
        dispatch(fetchError(error.message));
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(fetchSuccess());
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(fetchSuccess());
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  /**
   * @function userSignOut
   * @description Sign out user
   */
  const userSignOut = async () => {
    dispatch(fetchStart());

    try {
      await httpClient.post("users/logout/");
      await clearUser();
      dispatch(fetchSuccess("Successfully logged out"));
      history.push("/signin");
    } catch (error) {
      fetchError(error.message);
    } finally {
      dispatch(fetchEnd());
    }
  };

  /**
   * @function clearUser
   * @description Clear user data from local storage and cookies
   */
  const clearUser = async () => {
    Cookies.remove("enlis_token");
    Cookies.remove("enlis_user");
    Cookies.remove("enlis_program");
    setLoadingUser(false);
    fetchError("");
    dispatch(userAuthSignOut());
    dispatch(setInitUrl(''));
  };

  /**
   * @function setUser
   * @description set user data in local storage and cookies
   * @param {object} user
   */
  const setUser = (user) => {
    Cookies.set("enlis_user", JSON.stringify(user));
    dispatch(setAuthUser(user));
  };

  
  useEffect(() => {
    httpClient
      .get("/users/me/")
      .then(({ data }) => {
        if (data) {
          dispatch(setAuthUser(data));
        }
        setLoadingUser(false);
      })
      .catch(() => {
        clearUser();
        dispatch(fetchEnd());
      });
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    userLogin,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
