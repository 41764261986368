import { fetchEnd, fetchError, fetchStart, fetchSuccess } from "./Common";
import {
  SET_ADMIN_USERS,
  FETCH_YES_NO_UNKNOWN_LIST,
  FETCH_TEST_TYPE_CONFIG_LIST,
  FETCH_FACILITY_TEST_TYPE_SERVICE_LIST,
  FETCH_TEST_TYPE_SERVICE_LIST,
  FETCH_FACILITY_TAT_SETTING_LIST,
  FETCH_GLOBAL_TAT_SETTING_LIST,
  FETCH_FACILITY_TEST_TYPE_COST_LIST,
  FETCH_TEST_TYPE_COST_LIST,
  FETCH_FACILITY_ADMIN_LIST,
  FETCH_MACHINE_SETTINGS_LIST,
  FETCH_USER_LIST,
  FETCH_USER_LOCATION_LIST,
} from "constants/ActionTypes";
import { httpClient } from "util/Api";
import Cookies from "js-cookie";


// for getting Facility list
export const fetchFacilityAdminList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/users/`, { params: { facility: params } })
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_FACILITY_ADMIN_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Facility list
export const fetchUserLocationList = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/location_users/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_USER_LOCATION_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Facility list
export const fetchMachineSettingsList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/machine_setting/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_MACHINE_SETTINGS_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};
// for getting Test Type cost list
export const fetchTestTypeCostList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/test_cost/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_TEST_TYPE_COST_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Yes no list
export const fetchYesNoUnknowntList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/yes_no_unknown/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_YES_NO_UNKNOWN_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Yes no list
export const fetchTestTypeConfigList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/test_config_add_new/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_TEST_TYPE_CONFIG_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Test Type cost list
export const fetchFacilityTestTypeCostList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/facility_test_cost/?facility=${params}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_FACILITY_TEST_TYPE_COST_LIST,
          payload: data.data,
        });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Test Type cost list
export const fetchTestTypeServiceList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/test_service/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_TEST_TYPE_SERVICE_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Test Type cost list
export const fetchFacilityTestTypeServiceList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/facility_test_service/?facility=${params}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_FACILITY_TEST_TYPE_SERVICE_LIST,
          payload: data.data,
        });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Global TAT setting list
export const fetchGlobalTATSettingList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/global_tat/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_GLOBAL_TAT_SETTING_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const fetchFacilityTATSettingList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/facility_tat/?facility=${params}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_FACILITY_TAT_SETTING_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// for getting Facility list
export const fetchFacilityUserList = (params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(`/users/`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_USER_LIST, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};
// action to create an Admin user
export const createAdmin = (formData) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .post("users/", formData)
      .then((res) => {
        dispatch(
          fetchSuccess(
            "New admin user has been created successfully, Redirecting to the list of users"
          )
        );
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const updateAdminUser = (id, formData) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .put(`users/${id}/`, formData)
      .then((res) => {
        dispatch(fetchSuccess("Admin user successfully updated"));
      })
      .catch((error) => {
        dispatch(fetchError(error));
        throw error;
      });
  };
};


// action to create an machine setting
export const createMachineSetting = (formData) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .post("machine_setting/", formData)
      .then((res) => {
        dispatch(
          fetchSuccess(
            "New setting has been created successfully, Redirecting to the list of users"
          )
        );
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const updateMachineSetting = (id, formData) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .patch(`machine_setting/${id}/`, formData)
      .then((res) => {
        dispatch(fetchSuccess("Setting successfully updated"));
      })
      .catch((error) => {
        dispatch(fetchError(error));
        throw error;
      });
  };
};

// action to create an TestType cost
export const createTestTypeCostSetting = (formData) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .post("test_cost/", formData)
      .then((res) => {
        dispatch(
          fetchSuccess(
            "New setting has been created successfully, Redirecting to the list of users"
          )
        );
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

// action to create an Admin user
export const createLabConfiguration = (formData) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .post("test_configuration/", formData)
      .then((res) => {
        dispatch(
          fetchSuccess(
            "New setting has been created successfully, Redirecting to the list of users"
          )
        );
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const updateLabConfiguration = (id, formData) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .put(`test_configuration/${id}/`, formData)
      .then((res) => {
        dispatch(fetchSuccess("Setting successfully updated"));
      })
      .catch((error) => {
        dispatch(fetchError(error));
        throw error;
      });
  };
};
// Action to get a list of Admin users
export const fetchAdminList = (_params) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .get(_params ? `users/?username=${_params}` : `users/`)
      .then((res) => {
        dispatch(fetchSuccess());
        dispatch({ type: SET_ADMIN_USERS, payload: res.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      });
  };
};

export const createFacilityTAT = (values) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .post("/facility_test_cost/", values)
      .then((data) => {
        if (data.status === 201) {
          dispatch(fetchSuccess("New setting has been created successfully"));
          dispatch({
            type: FETCH_FACILITY_TAT_SETTING_LIST,
            payload: data.data,
          });
        } else {
          dispatch(fetchError(data.error));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      })
      .finally((error) => {
        dispatch(fetchEnd());
      });
  };
};

// Update test cost config
export const updateFacilityTAT = ({ id, formData }) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .patch(`/facility_tat/${id}/`, formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSuccess("New setting has been updated successfully"));
          dispatch({
            type: FETCH_FACILITY_TAT_SETTING_LIST,
            payload: res.data,
          });
        } else {
          dispatch(fetchError(res.error));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      })
      .finally((error) => {
        dispatch(fetchEnd());
      });
  };
};

//Submitting result form
export const createFacilityTestServiceResult = ({formData}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .post("/facility_test_service/", formData)
      .then((data) => {
        if (data.status === 201) {
          dispatch(fetchSuccess("New setting has been created successfully"));
        } else {
          dispatch(fetchError(data.error));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      })
      .finally((error) => {
        dispatch(fetchEnd());
      });
  };
};

// Update test cost config
export const updateTestServiceConfig = ({ id, formData }) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    await httpClient
      .patch(`/facility_test_service/${id}/`, formData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchSuccess("New setting has been updated successfully"));
        } else {
          dispatch(fetchError(res.error));
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
        throw error;
      })
      .finally((error) => {
        dispatch(fetchEnd());
      });
  };
};

/**
 * @function: GetUsersByFacility
 */

export const GetUsersByFacility = () => {
  return async (dispatch) => {
    const userData = Cookies.get("enlis_user");
    const { facility } = JSON.parse(userData);
    dispatch(fetchStart());
    try {
      const { data } = await httpClient.get(`/users/?facility=${facility}`);
      dispatch(fetchSuccess());
      return data;
    } catch (error) {
      dispatch(fetchError(error.message));
      throw error;
    }
  };
};


