import { Modal } from "antd";
import React from "react";
// import { IoIosLock } from "react-icons/io";

/**
 * @author davidbenard
 * @function LoginLoading
 **/

const LoginLoading = ({ showModal }) => {
  return (
    <Modal
      title="Logging in"
      centered
      visible={showModal}
      footer={null}
      className="loading_modal"
      closable={false}
    >
      <div className="login_modal_container">
        <div className="spinner-circle">
          <div className="spinner" />
          {/*<IoIosLock className="icon" />*/}
        </div>
        <p>Please wait...</p>
      </div>
    </Modal>
  );
};

export default LoginLoading;
