import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper gx-p-3">
      <Switch>
        <Route
          path={`${match.url}dashboards`}
          component={asyncComponent(() => import("./Dashboards"))}
        />
      </Switch>
    </div>
  );
};

export default App;
