/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, DatePicker, Row, Select } from "antd";
import { getDashboardData } from "appRedux/actions/Common";
import AppNotificationContainer from "components/AppNotificationContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "styles/pages/enlis-dashboard.less";
import Auxiliary from "util/Auxiliary";
//
// import AgeGradePositive from "../components/priorityDiseases/components/AgeGradePositive";
import IconWithTextCard from "components/dashboard/IconWithTextCard";
// import LineGraph from "../components/priorityDiseases/components/LineGraph";
// import ProgramPrevalencePie from "../components/priorityDiseases/components/ProgramPrevalencePie";
// import StateBarchart from "../components/priorityDiseases/components/StateBarchart";
// import StateMap from "../components/priorityDiseases/components/StateMap";
// import TATBar from "../components/priorityDiseases/components/TATBar";
// import TestAgeGradeOverview from "../components/priorityDiseases/components/TestAgeGradeOverview";
// import TestResultOverview from "../components/priorityDiseases/components/TestResultOverview";
import { useAuth } from "../../authentication";

const districtList = ["Serowe", "KWENENG EAST"]

const IdsrPublic = ({queryRecord}) => {
  const {userLogin} = useAuth();
  const [username] = useState("arinze");
  const [password] = useState("bright1008");
  const [start_week, setStartWeek] = useState(null);
  const [start_year, setStartYear] = useState(null);
  const [end_year, setEndYear] = useState(null);
  const [end_week, setEndWeek] = useState(null);
  const [district, setDistrict] = useState(null);
  const dispatch = useDispatch();
  const {loading, error, message, dashboardData} = useSelector(({common}) => common);
  const {
    reportAggregateList,
  } = dashboardData || {};
  const {RangePicker} = DatePicker;

  const onChange = (date, dateString) => {
    let _dateStartString = dateString[0].split("-")
    let _dateEndString = dateString[1].split("-")
    setStartYear(_dateStartString[0])
    setStartWeek(_dateStartString[1].replace(/\D/g, ''))
    setEndYear(_dateEndString[0])
    setEndWeek(_dateEndString[1].replace(/\D/g, ''))
  };

  useEffect(() => {
    const loginAuth = async () => {
      await userLogin({username, password})
      dispatch(getDashboardData(start_week, start_year, end_week, end_year, district));
    };
    loginAuth();

  }, [dispatch])

  useEffect(() => {
    dispatch(getDashboardData(start_week, start_year, end_week, end_year, district));
  }, [start_week, start_year, end_week, end_year, district])

  const _dashboard_data= reportAggregateList ? JSON.parse(reportAggregateList) : {total_covid_cases: null,total_covid_death: null,}
  console.log(_dashboard_data)
  const operations = (
    <div className="gx-mx-sm-2">
      <Select
        showSearch
        allowClear
        placeholder="Select District"
        onChange={setDistrict}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            ?.toLowerCase()
            .localeCompare(optionB.children?.toLowerCase())
        }
        options={districtList?.map((item) => ({
          label: item,
          value: item,
        }))}
      />
      &nbsp;&nbsp;
      <RangePicker onChange={onChange} picker="week" style={{width: 300}}/>
    </div>
  );
  return (
    <Auxiliary>
      <Card
        title="This is fine"
        extra={operations}
        headStyle={{backgroundColor: "#FAFAFF"}}
        bodyStyle={{backgroundColor: "#FAFAFF"}}
        bordered={false}
      >
        <Row>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <IconWithTextCard
              icon="eye"
              iconColor=""
              title={
                _dashboard_data ? _dashboard_data?.total_covid_cases : 0
              }
              subTitle="Total client tested"
              cardBg="card2"
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <IconWithTextCard
              icon="contacts"
              iconColor=""
              title={
                _dashboard_data
                  ? _dashboard_data?.total_covid_death
                  : 0
              }
              subTitle="Collected samples"
              cardBg="card6"
            />
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={24}>
            <IconWithTextCard
              icon="contacts"
              iconColor=""
              title={
                _dashboard_data
                  ? ((_dashboard_data?.total_covid_death/_dashboard_data?.total_covid_cases)*100).toFixed(2) + "%"
                  : 0
              }
              subTitle="Pending samples"
              cardBg="card3"
            />
          </Col>
          {/*<Col xl={6} lg={12} md={12} sm={12} xs={24}>*/}
          {/*  <IconWithTextCard*/}
          {/*    icon="contacts"*/}
          {/*    iconColor=""*/}
          {/*    title={*/}
          {/*      reportAggregateList*/}
          {/*        ? reportAggregateList[0]?.total_result_approved*/}
          {/*        : 0*/}
          {/*    }*/}
          {/*    subTitle="Completed test"*/}
          {/*    cardBg="card4"*/}
          {/*  />*/}
          {/*</Col>*/}
          {/*<Col xl={12} lg={24} md={12} sm={24} xs={24}>*/}
          {/*  <TestResultOverview*/}
          {/*    subtitle="Overall samples"*/}
          {/*    positive={*/}
          {/*      reportAggregateList*/}
          {/*        ? reportAggregateList[0]?.total_testing_result_positive*/}
          {/*        : 0*/}
          {/*    }*/}
          {/*    negative={*/}
          {/*      reportAggregateList*/}
          {/*        ? reportAggregateList[0]?.total_testing_result_negative*/}
          {/*        : 0*/}
          {/*    }*/}
          {/*    desc="Testing result outcome"*/}
          {/*    count={*/}
          {/*      reportAggregateList*/}
          {/*        ? reportAggregateList[0]?.total_result_approved*/}
          {/*        : 0*/}
          {/*    }*/}
          {/*    children={*/}
          {/*      <ResponsiveContainer width="100%" height={360}>*/}
          {/*        <ProgramPrevalencePie*/}
          {/*          reportAggregateList={reportAggregateList}*/}
          {/*        />*/}
          {/*      </ResponsiveContainer>*/}
          {/*    }*/}
          {/*  />*/}
          {/*</Col>*/}
          {/*<Col xl={12} lg={24} md={12} sm={24} xs={24}>*/}
          {/*  <TATBar*/}
          {/*    reportTAT={*/}
          {/*      reportTAT*/}
          {/*        ? JSON.parse(reportTAT)*/}
          {/*        : [*/}
          {/*          {*/}
          {/*            average_turnaround_time: null,*/}
          {/*            date: null,*/}
          {/*          },*/}
          {/*        ]*/}
          {/*    }*/}
          {/*  />*/}
          {/*</Col>*/}

          {/*<Col xl={24} lg={24} md={24} sm={24} xs={24}>*/}
          {/*  <Card styleName="gx-card-full">*/}
          {/*    <LineGraph trendAnnotationList={trendAnnotationList}/>*/}
          {/*  </Card>*/}
          {/*</Col>*/}
          {/*<Col xl={24} lg={24} md={24} sm={24} xs={24}>*/}
          {/*  <Card styleName="gx-card-full">*/}
          {/*    <StateBarchart reportAnnotationList={reportAnnotationList}/>*/}
          {/*  </Card>*/}
          {/*</Col>*/}

          {/*<Col xl={24} lg={24} md={24} sm={24} xs={24}>*/}
          {/*  <Card styleName="gx-card-full">*/}
          {/*    <StateBarchart reportAnnotationList={reportAnnotationList}/>*/}
          {/*  </Card>*/}
          {/*</Col>*/}
          {/*<Col xl={12} lg={12} md={12} sm={24} xs={24}>*/}
          {/*  <Card styleName="gx-card-full">*/}
          {/*    <StateMap reportAnnotationList={reportAnnotationList}/>*/}
          {/*  </Card>*/}
          {/*</Col>*/}
          {/*<Col*/}
          {/*  lg={24}*/}
          {/*  md={24}*/}
          {/*  sm={24}*/}
          {/*  xs={24}*/}
          {/*>*/}
          {/*  <TestAgeGradeOverview*/}
          {/*    desc="Positive Test by Age and Gender"*/}
          {/*    children={*/}
          {/*      <ResponsiveContainer width="100%" height={365}>*/}
          {/*        <AgeGradePositive reportAggregateList={reportAggregateList}/>*/}
          {/*      </ResponsiveContainer>*/}
          {/*    }*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>
        <AppNotificationContainer
          message={message}
          loading={loading}
          error={error}
        />
      </Card>
    </Auxiliary>
  );
};

export default IdsrPublic;
