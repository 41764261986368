import React, { useState } from "react";
import { Button, Checkbox, Typography, Form, Input, Image } from "antd";
import { Link } from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import { useSelector } from "react-redux";
// import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import LoginLoading from "../components/Modal/LoginLoading";
import loginBanner from "../assets/vendors/image/login.png";
import logo from "../assets/vendors/image/isurveillance.png";
import spiral from "../assets/vendors/image/spiral.svg";


const SignIn = () => {
  const { isLoading, error, userLogin } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { Title } = Typography;

  const onFinishFailed = (errorInfo) => {};
  const { loading, message } = useSelector(({ common }) => common);

  const onFinish = async () => {
    await userLogin({ username, password });
  };

  return (
    <div className="login_container">
      <section>
        <div className="header_img">
          <Image src={logo} alt="" />
        </div>
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content d-sm-none">
                <div className="gx-app-logo-content-bg">
                  <img src={loginBanner} alt="login banner" />
                </div>
              </div>
              <div className="gx-app-login-content">
                <Title level={3}>Secure Login</Title>
                <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="gx-signin-form gx-form-row0"
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "The input is not valid username!",
                      },
                    ]}
                    name="username"
                  >
                    <Input
                      placeholder="Username"
                      prefix={
                        <UserOutlined style={{ background: "#ffffff" }} />
                      }
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                    name="password"
                  >
                    <Input.Password
                      prefix={
                        <LockOutlined style={{ background: "#FAFAFF" }} />
                      }
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <div className="gx-d-flex gx-justify-content-between gx-align-items-center my-5">
                    <Checkbox>
                      <span className="gx-signup-form-forgot gx-link">
                        Remember me
                      </span>
                    </Checkbox>
                    <Link to="#">Forgot password</Link>
                  </div>
                  <Button type="primary" block htmlType="submit">
                    Login
                  </Button>
                </Form>
                <p className="text-center mt-4">
                  Don’t have account? <Link to="#">Contact Our Admin</Link>
                </p>
              </div>
              <AppNotificationContainer
                loading={isLoading}
                error={error}
                message={message}
              />
            </div>
          </div>
        </div>
        {loading}
      </section>
      <div className="footer_img">
        <img src={spiral} alt="spiral design" />
      </div>
      {loading === true && <LoginLoading showModal={loading} />}
    </div>
  );
};

export default SignIn;
