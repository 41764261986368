// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_END = 'fetch_end';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const FETCH_GENERAL_ORDER_TEST_TYPE = 'FETCH_GENERAL_ORDER_TEST_TYPE';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const PH_TEST_SAMPLES_LOOK_UP = 'PH_TEST_SAMPLES_LOOK_UP';
export const FETCH_SEARCHED_SAMPLE_LIST = 'FETCH_SEARCHED_SAMPLE_LIST';
export const CREATE_GENERAL_ORDER_RESULT = 'CREATE_GENERAL_ORDER_RESULT';
export const FETCH_TEST_STATUS_CONFIGURATION = 'FETCH_TEST_STATUS_CONFIGURATION';
export const FETCH_PH_WORKPLAN_SAMPLE = 'FETCH_PH_WORKPLAN_SAMPLE';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const SET_PROGRAM = 'SET_PROGRAM';

// Order const
export const GET_All_ORDER_SUCCESS = 'GET_All_ORDER_SUCCESS';

// Users
export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const FETCH_CLIENT_LIST = 'FETCH_CLIENT_LIST';
// Clients
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const CLIENT_IDENTIFIER_TYPE = 'CLIENT_IDENTIFIER_TYPE';
export const CLIENTS_ARE_LOADING = 'CLIENTS_ARE_LOADING';

// Lookups
export const FETCH_STATE_LIST = 'FETCH_STATE_LIST';
export const CREATE_GENERAL_PH_SAMPLE = 'CREATE_GENERAL_PH_SAMPLE';
export const FETCH_COLLECTED_SAMPLE = 'FETCH_COLLECTED_SAMPLE';
export const FETCH_TEST_TYPE = 'FETCH_TEST_TYPE';
export const FETCH_LOCAL_GOVERNMENT_AREA_LIST = 'FETCH_LOCAL_GOVERNMENT_AREA_LIST';
export const CREATE_FACILITY = 'create_facility';
export const UPDATE_FACILITY = 'update_facility';
export const FETCH_FACILITY_LIST = 'FETCH_FACILITY_LIST';
export const FETCH_FACILITY_ADMIN_LIST = 'FETCH_FACILITY_ADMIN_LIST';
export const FETCH_MACHINE_SETTINGS_LIST = 'FETCH_MACHINE_SETTINGS_LIST';
export const SET_FILTER_TYPE = 'set_filter_type';
export const FETCH_PROGRAM_LIST = 'FETCH_PROGRAM_LIST';
export const FETCH_OCCUPATION_LIST = 'FETCH_OCCUPATION_LIST';
export const SET_ROLES = 'SET_ROLES';
export const SET_BENCH = 'SET_BENCH';
export const FETCH_METHOD = 'FETCH_METHOD';
export const FETCH_INSTRUMENT = 'FETCH_INSTRUMENT';
export const SET_SAMPLE_TYPE = 'SET_SAMPLE_TYPE';
export const FETCH_VIRAL_lOAD_SAMPLES = 'FETCH_VIRAL_lOAD_SAMPLES';
export const FETCH_VIRAL_lOAD_SAMPLE = 'FETCH_VIRAL_lOAD_SAMPLE';
export const FETCH_EID_SAMPLES = 'FETCH_EID_SAMPLES';
export const FETCH_EID_SAMPLE = 'FETCH_EID_SAMPLE';
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const TB_FORM_LIST = 'TB_FORM_LIST';
export const EID_FORM_LIST = 'EID_FORM_LIST';
export const VL_FORM_LIST = 'VL_FORM_LIST';
export const FETCH_TEST_CONFIGURATION = 'FETCH_TEST_CONFIGURATION';
export const TEST_CONFIG_LOOK_UP = 'TEST_CONFIG_LOOK_UP';


// Admin Users
export const SET_ADMIN_USERS = 'SET_ADMIN_USERS';

export const CREATE_TB = 'CREATE_TB';
export const FETCH_TB_SAMPLE = 'FETCH_TB_SAMPLE';
export const FETCH_TB_SAMPLES = 'FETCH_TB_SAMPLES';

export const CREATE_VIRAL_LOAD_FORM = 'CREATE_VIRAL_LOAD_FORM';
export const CREATE_EID_FORM = 'CREATE_EID_FORM';
export const FACILITY_LOOK_UP = 'FACILITY_LOOK_UP';
export const GENERAL_ORDER_LOOK_UP = 'GENERAL_ORDER_LOOK_UP';
export const CREATE_GENERAL_ORDER = 'CREATE_GENERAL_ORDER';
export const FETCH_GENERAL_ORDERED_SAMPLE = 'FETCH_GENERAL_ORDERED_SAMPLE';
export const FETCH_GENERAL_COLLECTED_SAMPLE = 'FETCH_GENERAL_COLLECTED_SAMPLE';
export const FETCH_GENERAL_APPROVED_SAMPLE = 'FETCH_GENERAL_APPROVED_SAMPLE';
export const FETCH_GENERAL_ORDER = 'FETCH_GENERAL_ORDER';
export const SAMPLE_COLLECTION_LOOKUP_LIST = 'SAMPLE_COLLECTION_LOOKUP_LIST';
export const FETCH_LAB_SECTION = 'FETCH_LAB_SECTION';
export const FETCH_ENTERED_RESULT_TEST_TYPE = 'FETCH_ENTERED_RESULT_TEST_TYPE';
export const FETCH_REJECTION_REASON = 'FETCH_REJECTION_REASON';
export const APPROVE_GENERAL_ORDER_RESULT = 'APPROVE_GENERAL_ORDER_RESULT';
export const FETCH_TEST_TYPE_COST_LIST = 'FETCH_TEST_TYPE_COST_LIST';
export const FETCH_FACILITY_TEST_TYPE_COST_LIST = 'FETCH_FACILITY_TEST_TYPE_COST_LIST';
export const CREATE_FACILITY_TEST_COST = 'CREATE_FACILITY_TEST_COST';
export const FETCH_FACILITY_TAT_SETTING_LIST = 'FETCH_FACILITY_TAT_SETTING_LIST';
export const FETCH_GLOBAL_TAT_SETTING_LIST = 'FETCH_GLOBAL_TAT_SETTING_LIST';
export const FETCH_EID_ORDERED_SAMPLE = 'FETCH_EID_ORDERED_SAMPLE';
export const FETCH_VIRAL_LOAD_ORDERED_SAMPLE = 'FETCH_VIRAL_LOAD_ORDERED_SAMPLE';
export const FETCH_VIRAL_LOAD_COLLECTED_SAMPLE = 'FETCH_VIRAL_LOAD_COLLECTED_SAMPLE';
export const FETCH_VIRAL_LOAD_APPROVED_SAMPLE = 'FETCH_VIRAL_LOAD_APPROVED_SAMPLE';
export const FETCH_EID_APPROVED_SAMPLE = 'FETCH_EID_APPROVED_SAMPLE';
export const FETCH_EID_COLLECTED_SAMPLE = 'FETCH_EID_COLLECTED_SAMPLE';
export const FETCH_TB_APPROVED_SAMPLE = 'FETCH_TB_APPROVED_SAMPLE';
export const FETCH_TB_COLLECTED_SAMPLE = 'FETCH_TB_COLLECTED_SAMPLE';
export const FETCH_TB_ORDERED_SAMPLE = 'FETCH_TB_ORDERED_SAMPLE';
export const FETCH_SEARCHED_TB_SAMPLE_LIST = 'FETCH_SEARCHED_TB_SAMPLE_LIST';
export const FETCH_PH_COLLECTED_SAMPLE = 'FETCH_PH_COLLECTED_SAMPLE';
export const FETCH_PH_APPROVED_SAMPLE = 'FETCH_PH_APPROVED_SAMPLE';
export const FETCH_SEARCHED_PH_SAMPLE_LIST = 'FETCH_SEARCHED_PH_SAMPLE_LIST';
export const FETCH_PH_ENTERED_RESULT_TEST_TYPE = 'FETCH_PH_ENTERED_RESULT_TEST_TYPE';
export const FETCH_ENTER_TEST_CONFIGURATION = 'FETCH_ENTER_TEST_CONFIGURATION';
export const FETCH_APPROVE_TEST_CONFIGURATION = 'FETCH_APPROVE_TEST_CONFIGURATION';
export const OPERATION_SUCCESSFUL = 'Operation successfully';
export const FETCH_PH_VERIFIED_RESULT = 'FETCH_PH_VERIFIED_RESULT';
export const FETCH_PH_VERIFIED_REPORT = 'FETCH_PH_VERIFIED_REPORT';
export const FETCH_TEST_TYPE_SERVICE_LIST = 'FETCH_TEST_TYPE_SERVICE_LIST';
export const FETCH_FACILITY_TEST_TYPE_SERVICE_LIST = 'FETCH_FACILITY_TEST_TYPE_SERVICE_LIST';
export const FETCH_YES_NO_UNKNOWN_LIST = 'FETCH_YES_NO_UNKNOWN_LIST';
export const FETCH_PROGRAM_DASHBOARD = 'FETCH_PROGRAM_DASHBOARD';
export const SERVICE_TEST_TAT_LIST = 'SERVICE_TEST_TAT_LIST';
export const FETCH_TB_APPROVED_SAMPLE_ENTERED_RESULT = 'FETCH_TB_APPROVED_SAMPLE_ENTERED_RESULT';
export const FETCH_TB_ENTERED_RESULT_TEST_TYPE = 'FETCH_TB_ENTERED_RESULT_TEST_TYPE';
export const FETCH_TB_TEST_CONFIGURATION = 'FETCH_TB_TEST_CONFIGURATION';
export const FETCH_FACILITY_TB_DASHBOARD = 'FETCH_FACILITY_TB_DASHBOARD';
export const FETCH_FACILITY_TB_TEST_TYPE_DASHBOARD = 'FETCH_FACILITY_TB_TEST_TYPE_DASHBOARD';
export const FETCH_TEST_TYPE_CONFIG_LIST = 'FETCH_TEST_TYPE_CONFIG_LIST';
export const FETCH_GENERAL_TEST_CONFIGURATION = 'FETCH_GENERAL_TEST_CONFIGURATION';
export const FETCH_GO_TEST_CONFIGURATION = 'FETCH_GO_TEST_CONFIGURATION';
export const FETCH_GO_ENTERED_RESULT_TEST_TYPE = 'FETCH_GO_ENTERED_RESULT_TEST_TYPE';
export const FETCH_SEARCHED_ENTERED_RESULT_LIST = 'FETCH_SEARCHED_ENTERED_RESULT_LIST';
export const USER_LOCATION_LOOK_UP = 'USER_LOCATION_LOOK_UP';
export const FETCH_USER_LOCATION_LIST = 'FETCH_USER_LOCATION_LIST';


export const FETCH_DEPLOYED_ASSET = 'FETCH_DEPLOYED_ASSET';
export const CREATE_ASSET = 'CREATE_ASSET';
export const FETCH_PENDING_ASSET = 'FETCH_PENDING_ASSET';
export const FETCH_CONFIG_ASSET = 'FETCH_CONFIG_ASSET';
export const CREATE_GENERIC_ASSET = 'CREATE_GENERIC_ASSET';
export const CREATE_MANUFACTURER = 'CREATE_MANUFACTURER';
export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
export const FETCH_IN_OUT_CONFIG = 'FETCH_IN_OUT_CONFIG';
export const FETCH_PENDING_CONSUMABLES = 'FETCH_PENDING_CONSUMABLES';
export const CREATE_CONSUMABLE = 'CREATE_CONSUMABLE';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_LGA = 'UPDATE_LGA';
export const CREATE_DATA_COLLECTOR = 'CREATE_DATA_COLLECTOR';
export const FETCH_CONFIG_CONSUMABLE = 'FETCH_CONFIG_CONSUMABLE';
export const CREATE_GENERIC_CONSUMABLE = 'CREATE_GENERIC_CONSUMABLE';
export const CREATE_MEASURE_UNIT = 'CREATE_MEASURE_UNIT';
export const CREATE_RECEIVED_CONSUMABLE = 'CREATE_RECEIVED_CONSUMABLE';
export const FETCH_RECEIVED_CONSUMABLES = 'FETCH_RECEIVED_CONSUMABLES';
export const DASHBOARD_LOOK_UP = 'DASHBOARD_LOOK_UP';
export const LAB_NETWORK_LIST = 'LAB_NETWORK_LIST';
export const FETCH_PRIORITY_TEST_TYPE_DASHBOARD = 'FETCH_PRIORITY_TEST_TYPE_DASHBOARD';
export const UPDATED_RAW_DATA = 'UPDATED_RAW_DATA';

export const MAP_DATA = 'MAP_DATA';
export const LINE_DATA = 'LINE_DATA';
export const RECENT_DATA = 'RECENT_DATA';
export const RAW_DATA = 'RAW_DATA';
export const TERMS_DATA = 'TERMS_DATA';
export const UPDATE_PERIOD = 'UPDATE_PERIOD';
export const UPDATE_LINE_MAP = 'UPDATE_LINE_MAP';
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const SET_TERM = 'SET_TERM';
