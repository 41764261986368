import {
  DASHBOARD_DATA,
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  LINE_DATA,
  MAP_DATA,
  RAW_DATA,
  RECENT_DATA,
  SHOW_MESSAGE,
  TERMS_DATA,
  TOGGLE_COLLAPSED_NAV,
  UPDATE_LINE_MAP,
  UPDATE_PERIOD,
  WINDOW_WIDTH,
  UPDATED_RAW_DATA,
  SET_TERM,
} from "constants/ActionTypes";

const INIT_STATE = {
  error: "",
  currentProgram: "",
  currentPeriod: null,
  loading: false,
  lineMap: true,
  message: "",
  navCollapsed: true,
  mapDataList: null,
  lineDataList: null,
  recentDataList: null,
  rawDataList: null,
  updatedRawData: null,
  termList: [],
  dashboardData: null,
  termValue: null,
  mapIsLoading: false,

  superAdminRoles: ["SuperAdmin", "Admin"],
  dataEntryRoles: ["Data Clerk", "Admin", "Analyst"],
  filterType: {
    selectedIdentifier: "",
    searchText: "",
  },

  program: {},
  width: window.innerWidth,
  pathname: "/",
};

const CommonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    }
    case FETCH_START: {
      return { ...state, error: "", message: "", loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", loading: false, message: action.payload };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: "", error: action.payload };
    }

    case FETCH_END: {
      return { ...state, loading: false };
    }

    case SHOW_MESSAGE: {
      return { ...state, error: "", message: action.payload, loading: false };
    }

    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: "", message: "" };
    }

    case MAP_DATA: {
      return {
        ...state,
        mapDataList: action.payload,
      };
    }
    case LINE_DATA: {
      return {
        ...state,
        lineDataList: action.payload,
      };
    }
    case UPDATE_PERIOD: {
      return {
        ...state,
        currentPeriod: action.payload,
      };
    }
    case UPDATE_LINE_MAP: {
      return {
        ...state,
        lineMap: action.payload,
      };
    }
    case RECENT_DATA: {
      return {
        ...state,
        recentDataList: action.payload,
      };
    }
    case RAW_DATA: {
      return {
        ...state,
        rawDataList: action.payload,
      };
    }
    case UPDATED_RAW_DATA: {
      return {
        ...state,
        updatedRawData: action.payload,
      };
    }
    case TERMS_DATA: {
      return {
        ...state,
        termList: action.payload,
      };
    }
    case DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: action.payload,
      };
    }
    case SET_TERM: {
      return {
        ...state,
        termValue: action.payload,
      };
    }
    case "mapIsLoading": {
      return {
        ...state,
        mapIsLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default CommonReducer;
