import {
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  SIGNOUT_USER,
} from "constants/ActionTypes";

const INIT_STATE = {
  authUser: null,
  isLoadingUser: true,
  // send_forget_password_email: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        isLoadingUser: true,
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        isLoadingUser: action.payload,
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        authUser: null,
        isLoadingUser: false,
      };
    }

    default:
      return state;
  }
};
