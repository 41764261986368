import React from "react";

const IconWithTextCard = (props) => {
  const { icon, title, subTitle, cardBg } = props;
  let { iconColor } = props;

  return (
    <div
      className={`gx-media gx-d-flex gx-align-items-center gx-justify-content-between gx-flex-nowrap gx-w-100 dashboard_card ${
        cardBg ?? ""
      }`}
    >
      <div className="gx-media-body">
        <h2 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{title}</h2>
        <p className="gx-mb-0 gx-fs-mb">{subTitle}</p>
      </div>
      <div className="">
        <i
          className={`icon icon-${icon} gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
          style={{ fontSize: 45 }}
        />
      </div>
    </div>
  );
};

export default IconWithTextCard;
